import {
  type RegisterMembershipScheme,
  type ContactFormScheme,
} from '@/features/user/constants/schemas';

type UserCredential = {
  email?: string;
  code?: string;
};

type Reason = {
  id: number;
  content: string;
};

export const useUserState = () => {
  /**
   * ユーザーの認証情報
   * NOTE: cognitoにコード認証するとき・会員情報変更の前に必要なため保存しておく
   * 　　　 実装によっては不要になるかもしれない
   */
  const userCredentials = useState<UserCredential | undefined>(
    'userCredentials',
    () => undefined,
  );

  /**
   * 現在編集中の会員情報
   */
  const editingMembership = useState<RegisterMembershipScheme | undefined>(
    'editingMembership',
    () => undefined,
  );

  /**
   * 現在編集中のお問い合わせ情報
   */
  const editingContactForm = useState<ContactFormScheme | undefined>(
    'editingContactForm',
    () => undefined,
  );

  // 選択された退会理由
  const selectedUnsubscribeReason = useState<Reason | undefined>(
    'selectedUnsubscribeReason',
    () => undefined,
  );

  const setUserCredentials = (_credential: UserCredential | undefined) => {
    userCredentials.value = _credential;
  };

  const setEditingMembership = (
    _membership: RegisterMembershipScheme | undefined,
  ) => {
    editingMembership.value = _membership
      ? {
          lastName: _membership.lastName?.trim(),
          firstName: _membership.firstName?.trim(),
          lastNameKana: _membership.lastNameKana?.trim(),
          firstNameKana: _membership.firstNameKana?.trim(),
          phoneNumber: _membership.phoneNumber?.map((v) => v.trim()) || [],
          zipCode: _membership.zipCode?.trim(),
          address: _membership.address?.trim(),
        }
      : undefined;
  };

  const setEditingContactForm = (_contact: ContactFormScheme | undefined) => {
    editingContactForm.value = _contact
      ? {
          lastName: _contact.lastName?.trim(),
          firstName: _contact.firstName?.trim(),
          lastNameKana: _contact.lastNameKana?.trim(),
          firstNameKana: _contact.firstNameKana?.trim(),
          birthYear: _contact.birthYear,
          birthMonth: _contact.birthMonth,
          birthDay: _contact.birthDay,
          email: _contact.email?.trim(),
          phoneNumber: _contact.phoneNumber?.map((v) => v.trim()) || [],
          inquiryType: _contact.inquiryType,
          inquiryContent: _contact.inquiryContent?.trim(),
        }
      : undefined;
  };

  /** 退会理由を更新 */
  const setUnsubscribeReason = (reason: Reason | undefined) => {
    selectedUnsubscribeReason.value = reason;
  };

  const resetAll = () => {
    setUserCredentials(undefined);
    setEditingMembership(undefined);
    setEditingContactForm(undefined);
    setUnsubscribeReason(undefined);
  };

  return {
    userCredentials: readonly(userCredentials),
    editingMembership: readonly(editingMembership),
    editingContactForm: readonly(editingContactForm),
    selectedUnsubscribeReason: readonly(selectedUnsubscribeReason),
    setUserCredentials,
    setEditingMembership,
    setEditingContactForm,
    setUnsubscribeReason,
    resetAll,
  };
};
