import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';

const get: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json({
      version: {
        iosRequiredVersion: '1.0.0',
        androidRequiredVersion: '1.0.0',
      },
      storeUrl: {
        ios: 'https://google.co.jp',
        android: 'https://google.co.jp',
      },
    }),
  );
};

const mockVersion = { get };
export default mockVersion;
