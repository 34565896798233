import dayjs from 'dayjs';
import IconLine from '@/components/icons/IconLine.vue';
import IconApple from '@/components/icons/IconApple.vue';
import IconGoogle from '@/components/icons/IconGoogle.vue';

const currentYear = dayjs().year();
// 現在から過去130年分を表示
const YEAR_LIMIT = 130;
/** 生年月日セレクトボックス表示用の年データ */
export const yearOptions = [...Array(YEAR_LIMIT).keys()].map((i) => {
  const v = String(currentYear - i);
  return {
    id: v,
    value: v,
    label: v,
  };
});

const MONTH_IN_YEAR = 12;
/** 生年月日セレクトボックス表示用の月データ */
export const monthOptions = [...Array(MONTH_IN_YEAR).keys()].map((i) => {
  // 常に0で埋めた2桁表示
  const v = String(i + 1).padStart(2, '0');
  return {
    id: v,
    value: v,
    label: v,
  };
});

export const COGNITO_PROVIDER_IDS = {
  NAVIPARK: 1,
  LINE: 2,
  APPLE: 3,
  GOOGLE: 4,
} as const;

export type ProviderIdValue =
  (typeof COGNITO_PROVIDER_IDS)[keyof typeof COGNITO_PROVIDER_IDS];

export const COGNITO_PROVIDERS = [
  { id: COGNITO_PROVIDER_IDS.NAVIPARK, name: 'Navipark' },
  { id: COGNITO_PROVIDER_IDS.LINE, name: 'LINE' },
  { id: COGNITO_PROVIDER_IDS.APPLE, name: 'SignInWithApple' },
  { id: COGNITO_PROVIDER_IDS.GOOGLE, name: 'Google' },
] as const;

// 外部連携プロバイダのアイコン
export const providerIcons = [
  {
    providerId: COGNITO_PROVIDER_IDS.LINE,
    name: 'LINE',
    icon: IconLine,
  },
  {
    providerId: COGNITO_PROVIDER_IDS.APPLE,
    name: 'Apple',
    icon: IconApple,
  },
  {
    providerId: COGNITO_PROVIDER_IDS.GOOGLE,
    name: 'Google',
    icon: IconGoogle,
  },
];

// その他退会理由のID
export const OTHER_REASON_ID = 99;
