/**
 * ログイン・会員登録のフローコントロール用middleware
 */
import { useLoginState } from '@/composables/store/useLoginStore';
import { UserRepository } from '@/features/user/api/userRepository';
import { useCompareVersions } from '@/composables/useCompareVersions';
import { useDialogState } from '@/composables/store/useDialogStore';
import { getPathObject } from '@/utils/useHelper';

export default defineNuxtRouteMiddleware(async (to, from) => {
  // 認証に対応していないバージョンの場合は処理をスキップ
  const { isAppSupportCognitoAuth } = useCompareVersions();
  if (!isAppSupportCognitoAuth()) return;

  // 指定のページ・フローへの遷移はスキップ
  if (to.path === '/agreement' || to.path === '/tutorial') return;

  if (
    [
      'sign-in',
      'sign-up',
      'sign-out',
      'reset-password',
      'contact-form',
      'sns-integration',
    ].includes(to.meta.flow as string)
  )
    return;

  const {
    useWithLogIn,
    isCompleteMembershipInfo,
    setUseWithLogIn,
    setIsCompleteMembershipInfo,
    setHeaderMemberToken,
  } = useLoginState();
  const { getUseWithLogIn, getSnsRedirectPath, setSnsRedirectPathToApp } =
    useFlutterConnection();
  const { isSignedIn, getAccessToken, getCurrentProviderId, handleSignOut } =
    useCognitoAuth();
  const { addErrorStack } = useDialogState();

  // SNSログイン時のリダイレクト処理
  // ① SNSログイン画面からTOPへリダイレクト
  // ② クエリを引き継ぎ、snsRedirectPathへリダイレクト先を変更
  const snsRedirectPath = await getSnsRedirectPath();
  if (snsRedirectPath) {
    const { path, queries } = getPathObject(snsRedirectPath);
    await setSnsRedirectPathToApp(undefined); // 初期化
    return navigateTo({
      path,
      query: { ...queries, ...to.query },
    });
  }

  if (useWithLogIn.value === undefined) {
    // グローバルStateに無い場合「会員登録有無」をflutter側から取得して保存
    const _useWithLogIn = await getUseWithLogIn();
    if (_useWithLogIn != null) setUseWithLogIn(_useWithLogIn);
  }

  // NOTE: 「会員登録せずに使う」を選択したユーザーはそのまま進める
  if (useWithLogIn.value === false) return;

  // NOTE: ログインしていない場合はログイン画面へ遷移
  const signedIn = await isSignedIn();
  if (!signedIn) {
    return navigateTo({ path: '/sign-in', query: to.query });
  }

  // APIリクエストヘッダーに付与するためのCognitoのAuthTokenを取得・保持（ページ遷移ごとに取得）
  const token = await getAccessToken();
  await setHeaderMemberToken(token);

  // NOTE: 会員情報が登録されているか判定
  if (isCompleteMembershipInfo.value === undefined) {
    const repository = UserRepository();
    // バックエンド側から会員情報を取得
    const {
      data: d1,
      error: e1,
      execute: execute1,
    } = await repository.getMemberInfo();

    // 連携はされているが会員情報が登録されていない場合
    if (e1.value && e1.value.data?.code === 203) {
      await setIsCompleteMembershipInfo(false);
      return navigateTo('/membership/register');
    }

    // サインアップ時にアクセストークンと会員情報の連携が失敗した場合
    if (e1.value && [400, 403].includes(e1.value.data?.code)) {
      console.log('ユーザーが連携されていません。再度連携を試みます。');

      // 連携前にヘッダーに付与するCognitoのAuthTokenを削除
      await setHeaderMemberToken(undefined);
      // 連携処理
      const providerId = await getCurrentProviderId();
      const { error: e2 } = await repository.connectAccount(token, providerId);

      if (!e2.value) await execute1();
      if (e2.value) {
        // NOTE: ここで「会員登録をせずに利用するフロー」へ誘導しないと、ログイン済みだが会員登録していないユーザーが発生する
        await setUseWithLogIn(false);
        addErrorStack({
          title: 'アカウント連携中にエラーが発生しました',
          message: '引き続き会員登録が不要な機能は使うことができます',
          code: 401,
        });
        await sleep(1500); // SNSサインアウト後はリロードされてしまうため、エラーメッセージ表示のために待機
        await handleSignOut('/');
        return;
      }
    }

    if (e1.value && ![400, 401, 403].includes(e1.value.data?.code)) {
      await setUseWithLogIn(false);
      addErrorStack({
        title: '会員情報取得時にエラーが発生しました。',
        message: 'もう一度ログインしてください',
        code: 401,
      });
      await sleep(1500); // SNSサインアウト後はリロードされてしまうため、エラーメッセージ表示のために待機
      await handleSignOut('/sign-in');
      return;
    }

    // 会員情報が登録されているかをグローバルStateに保存
    await setIsCompleteMembershipInfo(
      d1.value?.member && !isEmptyObject(d1.value?.member),
    );
  }

  // NOTE: ログインしているが会員情報を入力していない場合（サインアップ時に入力前に離脱した）は会員情報入力へ遷移
  if (
    to.meta.flow !== 'membership-register' &&
    isCompleteMembershipInfo.value === false
  ) {
    return navigateTo('/membership/register');
  }
});
