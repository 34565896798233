/**
 * バックエンドAPIとの接続用APIクライアント
 */

import { useLoginState } from '@/composables/store/useLoginStore';
import { useDialogState } from '@/composables/store/useDialogStore';
import { useCognitoAuth } from '@/composables/useCognitoAuth';
import { UserRepository } from '@/features/user/api/userRepository';

type _UseFetchPath<T> = Parameters<typeof useFetch<T>>[0];
export type UseFetchOptions<T> = Parameters<typeof useFetch<T>>[1];
type _UseFetchReturnValue<T> = ReturnType<typeof useFetch<T>>;

export const useNaviparkApiClient = async <T>(
  method: 'GET' | 'POST' | 'PUT' | 'DELETE',
  endpoint: _UseFetchPath<T>,
  options?: UseFetchOptions<T>,
): Promise<_UseFetchReturnValue<T>> => {
  const config = useRuntimeConfig();
  const router = useRouter();
  const repository = UserRepository();
  const {
    userId,
    headerMemberToken,
    authToken,
    accessKey,
    setUserId,
    setAuthToken,
    setHeaderMemberToken,
  } = useLoginState();
  const { addErrorStack } = useDialogState();
  const { handleSignOut, handleUnsubscribe, forceRefreshSession } =
    useCognitoAuth();

  const retryCount = ref(0);
  const MAX_RETRY_COUNT = 1;

  const __DEBUG__ = config.public.env !== 'prod';

  const fetchData = () => {
    return useFetch(endpoint, {
      ...options,
      method: method,
      baseURL: config.public.baseUrl,
      headers: {
        'User-Id': String(userId.value),
        Authorization: accessKey.value,
        'Auth-Token': authToken.value,
        'Member-Token': headerMemberToken.value || '',
      },
      // NOTE: レスポンス成功時の共通処理を定義する
      async onResponse({ request, response, options }) {
        if (__DEBUG__) {
          console.debug(
            `[${response.type.toUpperCase()}] ${response.url} => ${
              response.status
            }`,
            response._data,
          );
        }
      },
      // NOTE: エラー時の共通処理を定義する
      async onResponseError({ request, response, options }) {
        // 503エラー（メンテナンスページへ遷移）
        if (response.status === 503) {
          router.push({ path: '/maintenance', query: response._data });
          return;
        }
        // 401:会員認証エラー（アクセストークンが無効）
        if (response.status === 401 && response._data.code === 401) {
          // 指定回数までリトライ
          retryCount.value++;
          if (retryCount.value > MAX_RETRY_COUNT) {
            addErrorStack({
              title: '認証トークンが無効です',
              message: 'もう一度ログインしてください',
              code: 401,
            });
            await sleep(1500); // SNSサインアウト後はリロードされてしまうため、エラーメッセージ表示のために待機
            await handleSignOut('/sign-in/');
            return;
          }
          const token = await forceRefreshSession();
          if (token) {
            await setHeaderMemberToken(token);
            await fetchData();
          }
          return;
        }
        // 401:会員認証エラー（ログインしていない状態で叩かれた）
        if (response.status === 401 && response._data.code === 215) {
          router.replace({ path: '/sign-in/' });
          return;
        }
        // 401:会員認証エラー（退会済みのユーザーへの対応）
        if (response.status === 401 && response._data.code === 217) {
          if (!!headerMemberToken.value) {
            // ログインしている場合、認証情報を削除
            await handleUnsubscribe();
            router.replace({ path: '/sign-in/' });
            return;
          } else {
            // ログインしていない場合、新規ユーザーデータを生成して置換
            const { data } = await repository.create();
            if (!data.value)
              throw new Error('ユーザーの情報の生成に失敗しました。');
            await Promise.all([
              setUserId(data.value.user.id),
              setAuthToken(data.value.user.token),
              repository.registerDeviceInfo(),
            ]);
            return;
          }
        }

        console.error(
          `[${response.type.toUpperCase()}] ${response.url} => ${
            response.status
          }`,
          response._data,
        );
        const _error = createError({
          message: response._data.message,
          statusCode: response.status,
          data: response._data,
        });
        return Promise.reject(_error);
      },
    });
  };

  return fetchData();
};
