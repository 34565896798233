import { ResponseResolver, MockedRequest, restContext } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';

const getPerPage: ResponseResolver<MockedRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const coupons = [...Array(55).keys()].map((i) => ({
    id: i + 1,
    title: `ID:${i + 1} クーポンのタイトル`,
    description: 'クーポン説明クーポン説明\nクーポン説明クーポン説明',
    discountAmount: i % 2 ? 300 : 1000,
    limit: 10,
    caution: i % 2 ? '注意事項テキスト' : '',
    startsAt: '2023-01-01T00:00:00+09:00',
    endsAt: i % 7 ? '2023-11-10T00:00:00+09:00' : '2034-02-10T00:00:00+09:00',
    usedCount: 5,
  }));
  const paginationLimit = Number(req.url.searchParams.get('limit')) || 10;
  const pageLength = Math.ceil(coupons.length / paginationLimit);
  const slicedCoupons = [...Array(pageLength).keys()].map((i) =>
    coupons.slice(i * paginationLimit, (i + 1) * paginationLimit),
  );

  const pages = [...Array(6).keys()].map((i) => ({
    total: 55,
    currentPage: i + 1,
    perPage: 10,
    lastPage: 6,
    firstPageUrl: 'http://localhost:3333/users/10/payments?page=1',
    lastPageUrl: 'http://localhost:3333/users/10/payments?page=6',
    prevPageUrl: `http://localhost:3333/users/10/payments?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/payments?page=${i + 2}`,
  }));
  const currentPagePoint = Number(req.url.searchParams.get('page') || 1);
  const targetCouponPage = pages.find(
    (page) => page.currentPage === currentPagePoint,
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json({
      coupons:
        slicedCoupons[(Number(req.url.searchParams.get('page')) || 1) - 1],
      pagination: targetCouponPage,
    }),
  );
};

const mockCoupon = { getPerPage };
export default mockCoupon;
