<template>
  <VOverlay :is-active="isActive" @click:outside="clickOutside">
    <div class="overflow-scroll rounded-2xl bg-cWhite">
      <div class="flex flex-col justify-between">
        <slot name="contents" />
        <div class="flex justify-between text-linkBlue">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </VOverlay>
</template>

<script setup lang="ts">
  interface Props {
    isOpen?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    isOpen: false,
  });

  const emit = defineEmits<{
    (e: 'update:isOpen', value: boolean): void;
    (e: 'click:outside', value: MouseEvent): void;
  }>();

  const isActive = computed(() => props.isOpen);

  const clickOutside = (e: MouseEvent) => {
    emit('click:outside', e);
  };

  /**
   * ダイアログ展開時の背景スクロールの制御
   */
  const scrollTop = ref(0);
  const _onOpenDialog = () => {
    const $body = document.querySelector('body');
    if (!$body) return;
    scrollTop.value = window.scrollY;
    $body.style.position = 'fixed';
    $body.style.top = `-${scrollTop.value}px`;
    $body.style.left = '0';
    $body.style.right = '0';
  };

  const _onCloseDialog = () => {
    const $body = document.querySelector('body');
    if (!$body) return;
    $body.style.position = '';
    $body.style.top = '';
    window.scrollTo({ top: scrollTop.value });
  };

  watch(
    () => props.isOpen,
    (open) => {
      if (open) _onOpenDialog();
      else _onCloseDialog();
    },
  );

  onBeforeUnmount(_onCloseDialog);
</script>
