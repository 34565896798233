import { ResponseResolver, RestRequest, restContext } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';

/** 生成する定期利用券の数 */
const ITEM_NUM = 101;
/** 最初に表示するページ番号 */
const FIRST_PAGE = 1;
/** デフォルトのページごとの定期利用券数 */
const DEFAULT_PER_PAGE = 10;
// 削除済み駐車場とする条件
const isParkingDeleted = (_i: number) => _i % 10 === 8; // ID末尾が8
// 削除済み駐車場とする条件
const isReceiptExpired = (_i: number) => _i % 10 === 9; // ID末尾が9

const getDetail: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const passId = Number(req.params.passId || 1);
  const passDetails = [...Array(ITEM_NUM).keys()].map((i) => ({
    id: i + 1,
    amount: 10000 * (i + 1),
    availableDays: 30,
    enteredAt: '2023-01-01T15:20:11+09:00',
    leavedAt: '2023-01-01T15:20:11+09:00',
    validityPeriods: [
      {
        startDate: '2024-03-01T00:00:00+09:00',
        endDate: '2024-03-30T00:00:00+09:00',
        remain: 10,
      },
      {
        startDate: '2024-04-01T00:00:00+09:00',
        endDate: '2024-04-30T00:00:00+09:00',
        remain: 10,
      },
      {
        startDate: '2024-05-01T00:00:00+09:00',
        endDate: '2024-06-30T00:00:00+09:00',
        remain: 10,
      },
    ],
  }));
  const targetPass = passDetails.find((pass) => pass.id === passId);

  return delayedResponse(
    ctx.status(200),
    ctx.json({
      pass: targetPass,
    }),
  );
};

const getPaymentsPerPage: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const perPage = Number(req.url.searchParams.get('limit')) || DEFAULT_PER_PAGE;
  const lastPage = Math.ceil(ITEM_NUM / perPage);
  const currentPage = Number(req.url.searchParams.get('page') || FIRST_PAGE);

  const pages = [...Array(ITEM_NUM).keys()].map((i) => ({
    total: ITEM_NUM,
    currentPage: i + 1,
    perPage,
    lastPage,
    firstPageUrl: 'http://localhost:3333/users/10/passes?page=1',
    lastPageUrl: `http://localhost:3333/users/10/passes?page=${lastPage}`,
    prevPageUrl: `http://localhost:3333/users/10/passes?page=${i}`,
    nextPageUrl: `http://localhost:3333/users/10/passes?page=${i + 2}`,
  }));
  const targetPage = pages.find((page) => page.currentPage === currentPage);

  const payments = [...Array(ITEM_NUM).keys()].map((i) => ({
    id: i + 1,
    parkingId: i + 1,
    passId: 10,
    parkingName: isParkingDeleted(i + 1)
      ? `ID:${i + 1} 削除済み駐車場`
      : isReceiptExpired(i + 1)
        ? `ID:${i + 1} 期限切れ領収書`
        : `ID:${i + 1} 新宿若葉第１`,
    parkingAddress: '東京都足立区千住2丁目9',
    startsAt: '2024-03-01T00:00:00+09:00',
    endsAt: '2024-03-30T23:59:59+09:00',
    remainDays: (i + 1) % 2 ? 10 : 30,
    paymentAmount: 20000,
    taxRate: 10,
    paymentType: 'クレジットカード',
    isParkingDeleted: isParkingDeleted(i + 1),
    isReceiptExpired: isReceiptExpired(i + 1),
  }));

  const slicedPayments = [...Array(lastPage).keys()].map((i) =>
    payments.slice(i * perPage, (i + 1) * perPage),
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json({
      passPayments:
        slicedPayments[
          Number(req.url.searchParams.get('page') || FIRST_PAGE) - 1
        ],
      pagination: targetPage,
    }),
  );
};

const getPaymentDetail: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  const passPaymentId = Number(req.params.passPaymentId || 1);
  const paymentDetails = [...Array(ITEM_NUM).keys()].map((i) => ({
    id: i + 1,
    parkingId: i + 1,
    parkingName: isParkingDeleted(i + 1)
      ? `ID:${i + 1} 削除済み駐車場`
      : isReceiptExpired(i + 1)
        ? `ID:${i + 1} 期限切れ領収書`
        : `ID:${i + 1} 新宿若葉第１`,
    parkingAddress: '東京都足立区千住2丁目9',
    startsAt: '2024-03-01T00:00:00+09:00',
    endsAt: '2024-03-30T23:59:59+09:00',
    remainDays: 30,
    paymentAmount: 20000,
    taxRate: 10,
    paymentType: 'クレジットカード',
    paymentStatus: 5,
    cardNumber: '411111********11',
    publishedAt: '2024-02-15T00:00:00+09:00',
    receiptNumber: 'N0123456789-012',
  }));
  const targetPayment = paymentDetails.find(
    (pass) => pass.id === passPaymentId,
  );

  return delayedResponse(
    ctx.status(200),
    ctx.json({
      passPayment: targetPayment,
    }),
  );
};

const getPassForTheParking: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  const parkingId = Number(req.params.parkingId || 1);
  const paymentDetails = [...Array(ITEM_NUM).keys()].map((i) => ({
    id: i + 1,
    parkingId: i + 1,
    parkingName: isParkingDeleted(i + 1)
      ? `ID:${i + 1} 削除済み駐車場`
      : isReceiptExpired(i + 1)
        ? `ID:${i + 1} 期限切れ領収書`
        : `ID:${i + 1} 新宿若葉第１`,
    parkingAddress: '東京都足立区千住2丁目9',
    startsAt: '2024-03-01T00:00:00+09:00',
    endsAt: '2024-03-30T23:59:59+09:00',
    remainDays: 10,
    paymentAmount: 20000,
    taxRate: 10,
    paymentType: 'クレジットカード',
    isReceiptExpired: isReceiptExpired(i + 1),
  }));
  const targetPayment = paymentDetails.find(
    (pass) => pass.parkingId === parkingId,
  );

  return delayedResponse(
    // // 正常系
    ctx.status(200),
    ctx.json({
      passPayments: [targetPayment],
    }),

    // // 認証エラー
    // ctx.status(401),
    // ctx.json({
    //   code: 401,
    //   message: '認証の有効期限が切れています。もう一度ログインしてください。',
    // }),
  );
};

const makePayment: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    // // 正常系
    ctx.status(200),
    ctx.json('<html><body>HTML Contents</body></html>'),

    // // 料金が途中で変更になった場合のエラー
    // ctx.status(422),
    // ctx.json({
    //   code: 209,
    //   message: '既に購入している定期利用家の期間と重複しています。',
    // }),
  );
};

const mockPass = {
  getDetail,
  getPaymentsPerPage,
  getPaymentDetail,
  getPassForTheParking,
  makePayment,
};
export default mockPass;
