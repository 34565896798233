export type ErrorContent = { title?: string; message: string; code?: number };

export const useDialogState = () => {
  /** お知らせポップアップの開閉状態 */
  const isOpenPopup = useState<boolean>('isOpenPopup', () => false);

  /** クレカ登録推奨ダイアログの開閉状態 */
  const isOpenRecommendRegisterDialog = useState<boolean>(
    'isOpenRecommendRegisterDialog',
    () => false,
  );

  /** GPS権限要求モーダルの開閉状態 */
  const isOpenGPSRequestDialog = useState<boolean>(
    'isOpenGPSRequestDialog',
    () => false,
  );

  /** アップデート要求モーダルの開閉状態と遷移先 */
  const updateRequestDialogState = useState<{ isOpen: boolean; url: string }>(
    'updateRequestDialogState',
    () => ({ isOpen: false, url: '' }),
  );

  /** エラーモーダルの表示エラースタック */
  const errorDialogState = useState<ErrorContent[]>(
    'errorDialogState',
    () => [],
  );

  const setIsOpenPopup = (_isOpen: boolean) => {
    isOpenPopup.value = _isOpen;
  };
  const setIsOpenRecommendRegisterDialog = (_isOpen: boolean) => {
    isOpenRecommendRegisterDialog.value = _isOpen;
  };
  const setIsOpenGPSRequestDialog = (_isOpen: boolean) => {
    isOpenGPSRequestDialog.value = _isOpen;
  };
  const setUpdateRequestDialogState = (_isOpen: boolean, _url: string) => {
    updateRequestDialogState.value = { isOpen: _isOpen, url: _url };
  };
  // エラーのスタックを追加
  const addErrorStack = (_error: ErrorContent) => {
    errorDialogState.value.push(_error);
  };
  // エラーのスタックを削除;
  const resolveErrorStack = () => {
    errorDialogState.value.shift();
  };

  return {
    isOpenPopup: readonly(isOpenPopup),
    isOpenRecommendRegisterDialog: readonly(isOpenRecommendRegisterDialog),
    isOpenGPSRequestDialog: readonly(isOpenGPSRequestDialog),
    updateRequestDialogState: readonly(updateRequestDialogState),
    errorDialogState: readonly(errorDialogState),
    setIsOpenPopup,
    setIsOpenRecommendRegisterDialog,
    setIsOpenGPSRequestDialog,
    setUpdateRequestDialogState,
    addErrorStack,
    resolveErrorStack,
  };
};
