import { default as about_45passqbaGJ80XgLMeta } from "/codebuild/output/src2583616656/src/src/pages/about-pass.vue?macro=true";
import { default as agreementygewB3PFiTMeta } from "/codebuild/output/src2583616656/src/src/pages/agreement.vue?macro=true";
import { default as confirm_45card_45aliasc9eG1OjsvYMeta } from "/codebuild/output/src2583616656/src/src/pages/card/[id]/confirm-card-alias.vue?macro=true";
import { default as confirmBjbdedh9d7Meta } from "/codebuild/output/src2583616656/src/src/pages/card/[id]/confirm.vue?macro=true";
import { default as edit_45card_45aliasc5gs31EsRqMeta } from "/codebuild/output/src2583616656/src/src/pages/card/[id]/edit-card-alias.vue?macro=true";
import { default as editpqe6heaVFWMeta } from "/codebuild/output/src2583616656/src/src/pages/card/[id]/edit.vue?macro=true";
import { default as indexkmd2ONiZbNMeta } from "/codebuild/output/src2583616656/src/src/pages/card/[id]/index.vue?macro=true";
import { default as confirmzzxnEYH3fmMeta } from "/codebuild/output/src2583616656/src/src/pages/card/create/confirm.vue?macro=true";
import { default as indexB4oXxQlJXdMeta } from "/codebuild/output/src2583616656/src/src/pages/card/create/index.vue?macro=true";
import { default as indexRq0e6couaUMeta } from "/codebuild/output/src2583616656/src/src/pages/card/index.vue?macro=true";
import { default as completeCPPywIjOI7Meta } from "/codebuild/output/src2583616656/src/src/pages/contact-form/complete.vue?macro=true";
import { default as confirmazcMiuKK4fMeta } from "/codebuild/output/src2583616656/src/src/pages/contact-form/confirm.vue?macro=true";
import { default as registerN8xmJqs3bNMeta } from "/codebuild/output/src2583616656/src/src/pages/contact-form/register.vue?macro=true";
import { default as contact1clKtCGsCIMeta } from "/codebuild/output/src2583616656/src/src/pages/contact.vue?macro=true";
import { default as indexDWk4w3r8xYMeta } from "/codebuild/output/src2583616656/src/src/pages/coupon/index.vue?macro=true";
import { default as faq8PpIAFFsgDMeta } from "/codebuild/output/src2583616656/src/src/pages/faq.vue?macro=true";
import { default as indexTqFTHhNQuVMeta } from "/codebuild/output/src2583616656/src/src/pages/history/index.vue?macro=true";
import { default as _91id_93rbIQMmA6R7Meta } from "/codebuild/output/src2583616656/src/src/pages/history/receipt/[id].vue?macro=true";
import { default as indexJJCCVNlwKTMeta } from "/codebuild/output/src2583616656/src/src/pages/index.vue?macro=true";
import { default as maintenanceuuiCvEHvbLMeta } from "/codebuild/output/src2583616656/src/src/pages/maintenance.vue?macro=true";
import { default as completeLU9zvR6jWtMeta } from "/codebuild/output/src2583616656/src/src/pages/membership/complete.vue?macro=true";
import { default as confirm8qJ3S8jTapMeta } from "/codebuild/output/src2583616656/src/src/pages/membership/confirm.vue?macro=true";
import { default as registerplyZu0DVDCMeta } from "/codebuild/output/src2583616656/src/src/pages/membership/register.vue?macro=true";
import { default as menuxBeStr1ncDMeta } from "/codebuild/output/src2583616656/src/src/pages/menu.vue?macro=true";
import { default as index7cybdulCr8Meta } from "/codebuild/output/src2583616656/src/src/pages/mypage/index.vue?macro=true";
import { default as indexooqtQi2DnkMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/integration/index.vue?macro=true";
import { default as sampleNucoCDg4TFMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/integration/sample.vue?macro=true";
import { default as completeVSaauvFJj5Meta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/email-edit/complete.vue?macro=true";
import { default as confirm_45code6eD87t02uJMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/email-edit/confirm-code.vue?macro=true";
import { default as indexls0coRsvjVMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/email-edit/index.vue?macro=true";
import { default as index00kcHuMWlTMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/index.vue?macro=true";
import { default as complete9wczFG0dmXMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/complete.vue?macro=true";
import { default as confirmidHndJdfzQMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/confirm.vue?macro=true";
import { default as indexJ7QOpVxca8Meta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/index.vue?macro=true";
import { default as update3NLRAOUXbYMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/update.vue?macro=true";
import { default as completeDnBZoj0L2uMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/password-edit/complete.vue?macro=true";
import { default as updatephyTrVFBVyMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/membership/password-edit/update.vue?macro=true";
import { default as indexbZjG08MJRVMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/pass/index.vue?macro=true";
import { default as _91id_93BBlD9ECwEwMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/pass/receipt/[id].vue?macro=true";
import { default as completeQGeKkDvUwaMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/sign-out/complete.vue?macro=true";
import { default as confirmSh1kp6nl2VMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/sign-out/confirm.vue?macro=true";
import { default as completeuBD6QgX7WvMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/unsubscribe/complete.vue?macro=true";
import { default as confirmMMaBY52yyXMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/unsubscribe/confirm.vue?macro=true";
import { default as consentODBkV3WTEsMeta } from "/codebuild/output/src2583616656/src/src/pages/mypage/unsubscribe/consent.vue?macro=true";
import { default as _91id_93zXuSoERK6aMeta } from "/codebuild/output/src2583616656/src/src/pages/notice/[id].vue?macro=true";
import { default as indexpLipGiC2NQMeta } from "/codebuild/output/src2583616656/src/src/pages/notice/index.vue?macro=true";
import { default as _91id_93tXMhgZD2iOMeta } from "/codebuild/output/src2583616656/src/src/pages/parking/[id].vue?macro=true";
import { default as pass_45agreementjlOtkgbFYqMeta } from "/codebuild/output/src2583616656/src/src/pages/pass-agreement.vue?macro=true";
import { default as completeHX4PkUGGUuMeta } from "/codebuild/output/src2583616656/src/src/pages/reset-password/complete.vue?macro=true";
import { default as confirm_45codeMAHZCbALxSMeta } from "/codebuild/output/src2583616656/src/src/pages/reset-password/confirm-code.vue?macro=true";
import { default as new_45passworda0xx7KTNiiMeta } from "/codebuild/output/src2583616656/src/src/pages/reset-password/new-password.vue?macro=true";
import { default as send_45emailHdd7ORBvVfMeta } from "/codebuild/output/src2583616656/src/src/pages/reset-password/send-email.vue?macro=true";
import { default as confirm_45codevcKvEVVYmSMeta } from "/codebuild/output/src2583616656/src/src/pages/sign-in/confirm-code.vue?macro=true";
import { default as indexcgJLYUMKTwMeta } from "/codebuild/output/src2583616656/src/src/pages/sign-in/index.vue?macro=true";
import { default as confirm_45codesgHQyG3LKGMeta } from "/codebuild/output/src2583616656/src/src/pages/sign-up/confirm-code.vue?macro=true";
import { default as index8r8elic3pGMeta } from "/codebuild/output/src2583616656/src/src/pages/sign-up/index.vue?macro=true";
import { default as tutorialZJXSoy3x4SMeta } from "/codebuild/output/src2583616656/src/src/pages/tutorial.vue?macro=true";
export default [
  {
    name: "about-pass",
    path: "/about-pass",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/about-pass.vue").then(m => m.default || m)
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/agreement.vue").then(m => m.default || m)
  },
  {
    name: "card-id-confirm-card-alias",
    path: "/card/:id()/confirm-card-alias",
    meta: confirm_45card_45aliasc9eG1OjsvYMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/[id]/confirm-card-alias.vue").then(m => m.default || m)
  },
  {
    name: "card-id-confirm",
    path: "/card/:id()/confirm",
    meta: confirmBjbdedh9d7Meta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/[id]/confirm.vue").then(m => m.default || m)
  },
  {
    name: "card-id-edit-card-alias",
    path: "/card/:id()/edit-card-alias",
    meta: edit_45card_45aliasc5gs31EsRqMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/[id]/edit-card-alias.vue").then(m => m.default || m)
  },
  {
    name: "card-id-edit",
    path: "/card/:id()/edit",
    meta: editpqe6heaVFWMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "card-id",
    path: "/card/:id()",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "card-create-confirm",
    path: "/card/create/confirm",
    meta: confirmzzxnEYH3fmMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/create/confirm.vue").then(m => m.default || m)
  },
  {
    name: "card-create",
    path: "/card/create",
    meta: indexB4oXxQlJXdMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/create/index.vue").then(m => m.default || m)
  },
  {
    name: "card",
    path: "/card",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/card/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-form-complete",
    path: "/contact-form/complete",
    meta: completeCPPywIjOI7Meta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/contact-form/complete.vue").then(m => m.default || m)
  },
  {
    name: "contact-form-confirm",
    path: "/contact-form/confirm",
    meta: confirmazcMiuKK4fMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/contact-form/confirm.vue").then(m => m.default || m)
  },
  {
    name: "contact-form-register",
    path: "/contact-form/register",
    meta: registerN8xmJqs3bNMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/contact-form/register.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "coupon",
    path: "/coupon",
    meta: indexDWk4w3r8xYMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/coupon/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "history",
    path: "/history",
    meta: indexTqFTHhNQuVMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: "history-receipt-id",
    path: "/history/receipt/:id()",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/history/receipt/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexJJCCVNlwKTMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "membership-complete",
    path: "/membership/complete",
    meta: completeLU9zvR6jWtMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/membership/complete.vue").then(m => m.default || m)
  },
  {
    name: "membership-confirm",
    path: "/membership/confirm",
    meta: confirm8qJ3S8jTapMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/membership/confirm.vue").then(m => m.default || m)
  },
  {
    name: "membership-register",
    path: "/membership/register",
    meta: registerplyZu0DVDCMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/membership/register.vue").then(m => m.default || m)
  },
  {
    name: "menu",
    path: "/menu",
    meta: menuxBeStr1ncDMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: "mypage",
    path: "/mypage",
    meta: index7cybdulCr8Meta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-integration",
    path: "/mypage/integration",
    meta: indexooqtQi2DnkMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/integration/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-integration-sample",
    path: "/mypage/integration/sample",
    meta: sampleNucoCDg4TFMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/integration/sample.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-email-edit-complete",
    path: "/mypage/membership/email-edit/complete",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/email-edit/complete.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-email-edit-confirm-code",
    path: "/mypage/membership/email-edit/confirm-code",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/email-edit/confirm-code.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-email-edit",
    path: "/mypage/membership/email-edit",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/email-edit/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership",
    path: "/mypage/membership",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-others-edit-complete",
    path: "/mypage/membership/others-edit/complete",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/complete.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-others-edit-confirm",
    path: "/mypage/membership/others-edit/confirm",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/confirm.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-others-edit",
    path: "/mypage/membership/others-edit",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-others-edit-update",
    path: "/mypage/membership/others-edit/update",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/others-edit/update.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-password-edit-complete",
    path: "/mypage/membership/password-edit/complete",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/password-edit/complete.vue").then(m => m.default || m)
  },
  {
    name: "mypage-membership-password-edit-update",
    path: "/mypage/membership/password-edit/update",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/membership/password-edit/update.vue").then(m => m.default || m)
  },
  {
    name: "mypage-pass",
    path: "/mypage/pass",
    meta: indexbZjG08MJRVMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/pass/index.vue").then(m => m.default || m)
  },
  {
    name: "mypage-pass-receipt-id",
    path: "/mypage/pass/receipt/:id()",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/pass/receipt/[id].vue").then(m => m.default || m)
  },
  {
    name: "mypage-sign-out-complete",
    path: "/mypage/sign-out/complete",
    meta: completeQGeKkDvUwaMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/sign-out/complete.vue").then(m => m.default || m)
  },
  {
    name: "mypage-sign-out-confirm",
    path: "/mypage/sign-out/confirm",
    meta: confirmSh1kp6nl2VMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/sign-out/confirm.vue").then(m => m.default || m)
  },
  {
    name: "mypage-unsubscribe-complete",
    path: "/mypage/unsubscribe/complete",
    meta: completeuBD6QgX7WvMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/unsubscribe/complete.vue").then(m => m.default || m)
  },
  {
    name: "mypage-unsubscribe-confirm",
    path: "/mypage/unsubscribe/confirm",
    meta: confirmMMaBY52yyXMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/unsubscribe/confirm.vue").then(m => m.default || m)
  },
  {
    name: "mypage-unsubscribe-consent",
    path: "/mypage/unsubscribe/consent",
    meta: consentODBkV3WTEsMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/mypage/unsubscribe/consent.vue").then(m => m.default || m)
  },
  {
    name: "notice-id",
    path: "/notice/:id()",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/notice/[id].vue").then(m => m.default || m)
  },
  {
    name: "notice",
    path: "/notice",
    meta: indexpLipGiC2NQMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/notice/index.vue").then(m => m.default || m)
  },
  {
    name: "parking-id",
    path: "/parking/:id()",
    meta: _91id_93tXMhgZD2iOMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/parking/[id].vue").then(m => m.default || m)
  },
  {
    name: "pass-agreement",
    path: "/pass-agreement",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/pass-agreement.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-complete",
    path: "/reset-password/complete",
    meta: completeHX4PkUGGUuMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/reset-password/complete.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-confirm-code",
    path: "/reset-password/confirm-code",
    meta: confirm_45codeMAHZCbALxSMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/reset-password/confirm-code.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-new-password",
    path: "/reset-password/new-password",
    meta: new_45passworda0xx7KTNiiMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/reset-password/new-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-send-email",
    path: "/reset-password/send-email",
    meta: send_45emailHdd7ORBvVfMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/reset-password/send-email.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-confirm-code",
    path: "/sign-in/confirm-code",
    meta: confirm_45codevcKvEVVYmSMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/sign-in/confirm-code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: indexcgJLYUMKTwMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-confirm-code",
    path: "/sign-up/confirm-code",
    meta: confirm_45codesgHQyG3LKGMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/sign-up/confirm-code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: index8r8elic3pGMeta || {},
    component: () => import("/codebuild/output/src2583616656/src/src/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "tutorial",
    path: "/tutorial",
    component: () => import("/codebuild/output/src2583616656/src/src/pages/tutorial.vue").then(m => m.default || m)
  }
]