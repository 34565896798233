import { ResponseResolver, restContext, RestRequest } from 'msw';
import { delayedResponse } from '@/utils/useMswCustomResponse';
import { COGNITO_PROVIDER_IDS } from '@/features/user/constants';

const get: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json({
      user: {
        id: 123456789,
        encryptedId: 'H-2212-2943',
      },
    }),
  );
};

const create: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json({
      user: {
        id: 123456789,
        token: 'authToken',
      },
    }),
  );
};

const registerDeviceInfo: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const getMemberInfo: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    // 正常系
    ctx.status(200),
    ctx.json({
      member: {
        id: 10,
        userId: 100,
        name: '田中一郎',
        lastName: '田中',
        firstName: '一郎',
        lastNameKana: 'たなか',
        firstNameKana: 'いちろう',
        email: 'sample@sonicmoov.com',
        phoneNumber: ['090', '1234', '5678'],
        zipCode: '1000005',
      },
    }),

    // 会員トークン切れ
    // ctx.status(401),
    // ctx.json({
    //   code: 401,
    //   message: '認証の有効期限が切れています。もう一度ログインしてください。',
    // }),

    // // 連携失敗
    // ctx.status(401),
    // ctx.json({
    //   code: 400,
    //   message: '連携に失敗しました。',
    // }),
  );
};

const updateMemberInfo: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json({
      member: {
        id: 10,
        userId: 100,
        name: '田中一郎',
        lastName: '田中',
        lastNameKana: 'たなか',
        firstName: '一郎',
        firstNameKana: 'いちろう',
        phoneNumber: ['090', '1234', '5678'],
        zipCode: '1000005',
      },
    }),
  );
};

const getConnections: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json({
      connections: [
        {
          providerId: COGNITO_PROVIDER_IDS.LINE,
          providerName: 'LINE',
          isConnected: true,
        },
        {
          providerId: COGNITO_PROVIDER_IDS.APPLE,
          providerName: 'Apple',
          isConnected: false,
        },
        {
          providerId: COGNITO_PROVIDER_IDS.GOOGLE,
          providerName: 'Google',
          isConnected: true,
        },
      ],
    }),
  );
};

const deleteMemberInfo: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const connectAccount: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(
    // 正常系
    ctx.status(201),

    // // 他のユーザーとすでに連携されている場合
    // ctx.status(422),
    // ctx.json({
    //   code: 204,
    //   message: '既に連携されているユーザーです。',
    // }),

    // // 連携失敗
    // ctx.status(401),
    // ctx.json({
    //   code: 400,
    //   message: '連携に失敗しました。',
    // }),
  );
};

const disconnectAccount: ResponseResolver<RestRequest, typeof restContext> = (
  req,
  res,
  ctx,
) => {
  return delayedResponse(ctx.status(204));
};

const getUnsubscribeReasons: ResponseResolver<
  RestRequest,
  typeof restContext
> = (req, res, ctx) => {
  return delayedResponse(
    ctx.status(200),
    ctx.json({
      reasons: [
        {
          id: 1,
          outline: '非会員として利用するが、会員機能・コンテンツは不要と感じた',
        },
        { id: 2, outline: '運転をする機会が減った' },
        {
          id: 3,
          outline: 'ナビパーク・ナビサイクルをあまり利用しなかった',
        },
        {
          id: 4,
          outline: 'アプリ決済よりも現地精算機決済の方が良かった',
        },
        { id: 5, outline: '通知・お知らせが多かった' },
        { id: 99, outline: 'その他' },
      ],
    }),
  );
};

const mockUser = {
  get,
  create,
  registerDeviceInfo,
  getMemberInfo,
  updateMemberInfo,
  deleteMemberInfo,
  getConnections,
  connectAccount,
  disconnectAccount,
  getUnsubscribeReasons,
};
export default mockUser;
