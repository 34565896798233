<script setup lang="ts">
  /**
   * アップデート要求が必要か判定する
   * NOTE: 現状、全てのページ遷移時に実行する
   */
  import { useLoginState } from '@/composables/store/useLoginStore';
  import { useUpdateChecker } from '@/composables/useUpdateChecker';

  const route = useRoute();
  const { currentVersion } = useLoginState();
  const { checkUpdate } = useUpdateChecker();

  watch(
    () => [currentVersion.value, route.path],
    async () => {
      await checkUpdate();
    },
    { immediate: true },
  );
</script>
