<template>
  <VDialog :is-open="!!currentError">
    <div
      class="mb-4 text-center text-base font-bold leading-[125%] text-cBlack"
    >
      <p>
        {{ currentError?.title || 'エラーが発生しました' }}
      </p>
    </div>
    <p class="text-sm font-medium leading-[140%] text-cBlack">
      {{ currentError?.message }}
    </p>

    <VBtn
      color="primary"
      rounded="normal"
      variants="outlined"
      size="lg"
      @click="resolve"
    >
      <span class="text-base leading-[125%]">閉じる</span>
    </VBtn>
  </VDialog>
</template>

<script setup lang="ts">
  import { useDialogState } from '@/composables/store/useDialogStore';
  import { useLoadingState } from '@/composables/store/useLoadingStore';

  const currentError = computed(() => errorDialogState.value[0]);
  const { clearLoadings } = useLoadingState();
  const { errorDialogState, resolveErrorStack } = useDialogState();

  // 現在表示中のエラーを解決
  const resolve = () => {
    resolveErrorStack();
  };

  watch(currentError, () => {
    if (!!currentError.value) {
      // ローディングの強制ストップ
      clearLoadings();
    }
  });
</script>
