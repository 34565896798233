import { UserRepository } from '@/features/user/api/userRepository';
import { useLoginState } from '@/composables/store/useLoginStore';

/** ユーザーIdの更新 */
export const useUpdateUser = () => {
  const repository = UserRepository();
  const { userId, setUserId } = useLoginState();

  const update = async () => {
    const { data } = await repository.get(userId.value);
    await setUserId(data.value.user.id);

    return { user: data.value.user };
  };

  return { update };
};
