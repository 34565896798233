import {
  signUp,
  resendSignUpCode,
  confirmSignUp,
  updateUserAttribute,
  signIn,
  signOut,
  signInWithRedirect,
  fetchAuthSession,
  getCurrentUser,
  autoSignIn,
  resetPassword,
  confirmResetPassword,
  updatePassword,
  confirmUserAttribute,
} from 'aws-amplify/auth';
import type {
  SignUpOutput,
  UpdateUserAttributeOutput,
  ResetPasswordOutput,
  SignInOutput,
  VerifiableUserAttributeKey,
} from 'aws-amplify/auth';
import { useCompareVersions } from '@/composables/useCompareVersions';
import { useResetState } from '@/composables/useResetState';
import { useUpdateUser } from '@/composables/useUpdateUser';
import { UserRepository } from '@/features/user/api/userRepository';
import { useLoginState } from './store/useLoginStore';
import { useLoadingState } from './store/useLoadingStore';
import {
  COGNITO_PROVIDER_IDS,
  COGNITO_PROVIDERS,
} from '@/features/user/constants';
import { useDialogState } from './store/useDialogStore';

export const useCognitoAuth = () => {
  const router = useRouter();
  const repository = UserRepository();
  const { setHasMembershipToApp } = useFlutterConnection();
  const { update: updateUserId } = useUpdateUser();
  const { isAppSupportCognitoAuth } = useCompareVersions();
  const { setUseWithLogIn, setHeaderMemberToken } = useLoginState();
  const { stopLoadings } = useLoadingState();
  const { addErrorStack } = useDialogState();
  const { resetStateOnSignOut, resetStateOnUnsubscribe } = useResetState();

  // エラーメッセージ
  const errorMessage = ref('');

  // CognitoのAuthトークンをリクエストヘッダーに付与
  const _setHeaderMemberToken = async () => {
    const token = await getAccessToken();
    await setHeaderMemberToken(token);
  };

  /**
   * ユーザー名とパスワードを入力して認証コードをメールで受け取る
   * @param email メールアドレス
   * @param password パスワード
   */
  const handleSignUp = async (email: string, password: string) => {
    try {
      const { nextStep } = await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: {
            enabled: true,
          },
        },
      });
      await _handleSignUpStep(nextStep);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, '新規登録');
      console.log(error);
      errorMessage.value = error.message_jp;
      stopLoadings('sign-up');
    }
  };

  /**
   * 指定メールアドレスに認証コードを再送する
   * @param email メールアドレス
   */
  const handleResendSignUpCode = async (email: string) => {
    try {
      await resendSignUpCode({
        username: email,
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, '仮登録認証コード再送信');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /**
   * サインアップの確認（メールで認証コードを受け取る場合）
   * @param email メールアドレス
   * @param confirmationCode 認証コード
   */
  const handleSignUpConfirmation = async (
    email: string,
    confirmationCode: string,
  ) => {
    try {
      const { nextStep } = await confirmSignUp({
        username: email,
        confirmationCode,
      });
      await _handleSignUpStep(nextStep);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, '仮登録認証');
      console.log(error);
      errorMessage.value = error.message_jp;
      stopLoadings('mail-confirmation');
    }
  };

  /**
   * ユーザー名とパスワードでサインイン
   * @param email メールアドレス
   * @param password パスワード
   */
  const handleSignIn = async (email: string, password: string) => {
    try {
      const { nextStep } = await signIn({ username: email, password });
      await _handleSignInStep(nextStep);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'ログイン');
      console.log(error);
      errorMessage.value = error.message_jp;
      stopLoadings('sign-in');
    }

    // TODO: 不要?
    await getCognitoTokens();
  };

  /** オートサインイン */
  const _handleAutoSignIn = async () => {
    try {
      const { nextStep } = await autoSignIn();
      await _handleAutoSignInStep(nextStep);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'オートサインイン');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** LINEでサインイン */
  const handleLineSignIn = async () => {
    try {
      // 事前にcognito関係の認証情報が残っている場合は削除
      await signOut();
      await signInWithRedirect({
        provider: { custom: 'LINE' },
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'LINEログイン');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** Appleでサインイン */
  const handleAppleSignIn = async () => {
    try {
      // 事前にcognito関係の認証情報が残っている場合は削除
      await signOut();
      await signInWithRedirect({
        provider: { custom: 'SignInWithApple' },
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'Appleログイン');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** Googleでサインイン */
  const handleGoogleSignIn = async () => {
    try {
      // 事前にcognito関係の認証情報が残っている場合は削除
      await signOut();
      await signInWithRedirect({
        provider: 'Google',
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'Googleログイン');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** LINEで連携 */
  const handleLineIntegration = async () => {
    try {
      await signInWithRedirect({
        provider: { custom: 'LINE' },
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'LINE連携');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** Appleで連携 */
  const handleAppleIntegration = async () => {
    try {
      await signInWithRedirect({
        provider: { custom: 'SignInWithApple' },
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'Apple連携');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** Googleで連携 */
  const handleGoogleIntegration = async () => {
    try {
      await signInWithRedirect({
        provider: 'Google',
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'Google連携');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** サインインしているかどうか */
  const isSignedIn = async () => {
    if (!isAppSupportCognitoAuth()) return false;
    try {
      const user = await getCurrentUser();
      return true;
    } catch (error) {
      return false;
    }
  };

  /** TOKEN類を取得 */
  const getCognitoTokens = async () => {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      return { accessToken, idToken };
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'ログイン情報取得');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** 特にAccessTokenを取得 */
  const getAccessToken = async () => {
    try {
      const lawTokens = await getCognitoTokens();
      const token = lawTokens?.accessToken?.toString();
      if (!token) {
        console.log(`NoToken`, token);
        return '';
      }
      return token;
    } catch (error: any) {
      console.log(`TokenError`, error);
      return '';
    }

    // if (!token)
    //   throw showError(
    //     'ログイン時にエラーが発生しました。再度アプリをインストールし直してください。',
    //   );
  };

  /** 現在ログインに使っているプロバイダーのIDを取得 */
  const getCurrentProviderId = async () => {
    try {
      const { username } = await getCurrentUser();
      const targetProvider = COGNITO_PROVIDERS.find((provider) =>
        username.includes(provider.name.toLocaleLowerCase()),
      );
      return targetProvider ? targetProvider.id : COGNITO_PROVIDER_IDS.NAVIPARK;
    } catch (error) {
      console.error(error);
      return COGNITO_PROVIDER_IDS.NAVIPARK;
    }
  };

  /**
   * ユーザーに入力させたパスワードが正しいかどうかを確認
   * @param password パスワード
   */
  const checkPassword = async (password: string) => {
    try {
      await updatePassword({ oldPassword: password, newPassword: password });
      return true;
    } catch (error: any) {
      console.log(error);
      errorMessage.value = 'パスワードが正しくありません';
      return false;
    }
  };

  /**
   * ユーザーの会員情報を更新
   * @param attributeKey 属性名
   * @param value 値
   */
  const handleUpdateAttribute = async (attributeKey: string, value: string) => {
    try {
      const output = await updateUserAttribute({
        userAttribute: {
          attributeKey,
          value,
        },
      });
      await _handleUpdateAttributeStep(output);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, '会員情報更新');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /**
   * ユーザーの会員情報変更の確認（メールで認証コードを受け取る場合）
   * @param attributeKey 属性名
   * @param confirmationCode 認証コード
   */
  const handleUpdateAttributeConfirmation = async (
    userAttributeKey: VerifiableUserAttributeKey,
    confirmationCode: string,
  ) => {
    try {
      await confirmUserAttribute({ userAttributeKey, confirmationCode });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'メールアドレス認証');
      console.log(error);
      errorMessage.value = error.message_jp;
    }
  };

  /**
   * パスワードをリセット
   * @param email メールアドレス
   */
  const handleResetPassword = async (email: string) => {
    try {
      const { nextStep } = await resetPassword({ username: email });
      await _handleResetPasswordStep(nextStep);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'パスワードリセット');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /**
   * リセットしたパスワードを更新（未ログイン時）
   * @param email メールアドレス
   * @param newPassword 新しいパスワード
   * @param confirmationCode 認証コード
   */
  const handleResetPasswordConfirmation = async (
    email: string,
    newPassword: string,
    confirmationCode: string,
  ) => {
    try {
      await confirmResetPassword({
        username: email,
        newPassword,
        confirmationCode,
      });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'パスワードリセット後の更新');
      console.error(error);
      errorMessage.value = error.message_jp;

      // 認証コードが不正な場合は、再度認証コード入力画面へリダイレクト
      if (
        error.name === 'ExpiredCodeException' ||
        error.name === 'CodeMismatchException'
      ) {
        router.push('/reset-password/confirm-code');
      }
    }
  };

  /**
   * パスワードを更新（ログイン中）
   * @param oldPassword 旧パスワード
   * @param newPassword 新パスワード
   */
  const handleUpdatePassword = async (
    oldPassword: string,
    newPassword: string,
  ) => {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'パスワード更新');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** サインアウト */
  const handleSignOut = async (redirectUrl?: string) => {
    try {
      await signOut();
      resetStateOnSignOut();
      await Promise.all([repository.registerDeviceInfo(), updateUserId()]);
      if (redirectUrl) router.replace(redirectUrl);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'ログアウト');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** 退会 */
  const handleUnsubscribe = async () => {
    try {
      await signOut();
      resetStateOnUnsubscribe();
      await Promise.all([
        // 退会後、会員でなくてもアプリを利用できるようにする
        setUseWithLogIn(false),
        setHasMembershipToApp(false),
        repository.registerDeviceInfo(),
        updateUserId(),
      ]);
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, '退会');
      console.error(error);
      errorMessage.value = error.message_jp;
    }
  };

  /** アクセストークンの強制的な更新 */
  const forceRefreshSession = async () => {
    try {
      const authSession = await fetchAuthSession({ forceRefresh: true });
      return authSession.tokens?.accessToken.toString();
    } catch (error: any) {
      error.message_jp = _getJpErrMessage(error, 'アクセストークンの更新');
      console.error(error);
      errorMessage.value = `${error.message_jp} 再度ログインしてください`;
    }
  };

  /**
   * サインアップフローをハンドリング
   * @param step CognitoAPIの返り値 "nextStep"
   */
  const _handleSignUpStep = async (step: SignUpOutput['nextStep']) => {
    const _step = step.signUpStep;
    switch (_step) {
      case 'CONFIRM_SIGN_UP':
        // 認証コードの入力画面へリダイレクト
        router.push('/sign-up/confirm-code');
        break;
      case 'COMPLETE_AUTO_SIGN_IN':
        // サインアップ後のオートサインイン処理
        await _handleAutoSignIn();
        console.log('オートサインインが完了しました'); /////

        // 連携前にヘッダーに付与するCognitoのAuthTokenを削除
        await setHeaderMemberToken(undefined);
        // バックエンドにアクセストークンを送信
        const token = await getAccessToken();
        const { error } = await repository.connectAccount(
          token,
          COGNITO_PROVIDER_IDS.NAVIPARK,
        );

        if (error.value) {
          // 会員登録しないで利用するフローへ
          await setUseWithLogIn(false);
          addErrorStack({
            title: 'アカウント連携中にエラーが発生しました',
            message: '引き続き会員登録が不要な機能は使うことができます',
            code: 401,
          });
          await sleep(1500); // SNSサインアウト後はリロードされてしまうため、エラーメッセージ表示のために待機
          await handleSignOut('/');
          return;
        }

        console.log('アカウント連携に成功しました');

        await _setHeaderMemberToken();
        await Promise.all([
          setHasMembershipToApp(true),
          setUseWithLogIn(true),
          repository.registerDeviceInfo(),
          updateUserId(),
        ]);
        router.push('/membership/register');
        break;
      case 'DONE':
        await Promise.all([setHasMembershipToApp(true), setUseWithLogIn(true)]);
        // 認証コード認証が完了したら会員情報登録画面へリダイレクト
        router.push('/membership/register');
        break;
      default:
        throw new Error(_step satisfies never);
    }
  };

  /**
   * サインインフローをハンドリング
   * @param step CognitoAPIの返り値 "nextStep"
   */
  const _handleSignInStep = async (step: SignInOutput['nextStep']) => {
    const _step = step.signInStep;
    switch (_step) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        // confirmSignIn()
        break;
      case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
        // confirmSignIn()
        break;
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        // confirmSignIn()
        break;
      case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
        // confirmSignIn()
        break;
      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        // confirmSignIn()
        break;
      case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
        // confirmSignIn()
        break;
      case 'RESET_PASSWORD':
        // パスワードリセット画面へリダイレクト
        router.push('/reset-password/send-email');
        break;
      case 'CONFIRM_SIGN_UP':
        router.push('/sign-in/confirm-code');
        break;
      case 'DONE':
        await Promise.all([
          await setUseWithLogIn(true),
          await setHasMembershipToApp(true),
          await _setHeaderMemberToken(),
        ]);
        await repository.registerDeviceInfo();
        await updateUserId();
        // NOTE: ログイン後はHOMEへリダイレクト
        router.push('/');
        break;
      default:
        throw new Error(_step satisfies never);
    }
  };

  /**
   * サインアップ後のオートサインインフローをハンドリング
   * @param step CognitoAPIの返り値 "nextStep"
   */
  const _handleAutoSignInStep = async (step: SignInOutput['nextStep']) => {
    const _step = step.signInStep;
    switch (_step) {
      case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
        // confirmSignIn()
        break;
      case 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE':
        // confirmSignIn()
        break;
      case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
        // confirmSignIn()
        break;
      case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
        // confirmSignIn()
        break;
      case 'CONFIRM_SIGN_IN_WITH_SMS_CODE':
        // confirmSignIn()
        break;
      case 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION':
        // confirmSignIn()
        break;
      case 'RESET_PASSWORD':
        // パスワードリセット画面へリダイレクト
        router.push('/reset-password/send-email');
        break;
      case 'CONFIRM_SIGN_UP':
        router.push('/sign-in/confirm-code');
        break;
      case 'DONE':
        console.log('オートサインインが完了しました'); /////
        break;
      default:
        throw new Error(_step satisfies never);
    }
  };

  /**
   * 登録情報更新処理をハンドリング
   * @param output CognitoAPIの返り値
   */
  const _handleUpdateAttributeStep = async (
    output: UpdateUserAttributeOutput,
  ) => {
    const _step = output.nextStep.updateAttributeStep;
    switch (_step) {
      case 'CONFIRM_ATTRIBUTE_WITH_CODE':
        console.log('認証コードで認証する必要があります');
        break;
      case 'DONE':
        // 会員情報登録完了の場合リダイレクト
        router.push('/membership/complete');
        break;
      default:
        throw new Error(_step satisfies never);
    }
  };

  /**
   * パスワードリセット処理をハンドリング
   * @param step CognitoAPIの返り値 "nextStep"
   */
  const _handleResetPasswordStep = async (
    step: ResetPasswordOutput['nextStep'],
  ) => {
    const _step = step.resetPasswordStep;
    switch (_step) {
      case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
        const codeDeliveryDetails = step.codeDeliveryDetails;
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`,
        );
        router.push('/reset-password/confirm-code');
        // Collect the confirmation code from the user and pass to confirmResetPassword.
        break;
      case 'DONE':
        break;
    }
  };

  /**
   * エラーハンドリング
   * @param error キャッチしたエラーオブジェクト
   * @param procName 任意の処理フロー識別名
   */
  const _getJpErrMessage = (error: any, procName: string) => {
    switch (error.name) {
      case 'UserNotConfirmedException':
        // ユーザのステータスが UNCONFIRMED の場合に起こる。
        // SignUp用のコードを再送し、ステータスを CONFIRMED にする必要がある。
        return 'メールアドレスの検証が完了していません。';
      case 'PasswordResetRequiredException':
        // Cognito コンソールでパスワードをリセット（ユーザープールにユーザをインポートする場合も含む）した場合に起こる。
        // パスワードをリセットする必要がある。
        return 'パスワードをリセットする必要があります。';
      case 'NotAuthorizedException':
        // 誤ったパスワードを入力した場合に起こる。
        // パスワードを間違え続けた場合にも起こり、error.message が 'Password attempts exceeded' になる。
        if (error.message == 'Password attempts exceeded') {
          return 'ログインの試行回数が上限を超えたため、一時的に認証できなくなりました。';
        } else {
          return 'メールアドレスまたはパスワードが正しくありません。';
        }
      case 'UserNotFoundException':
        return 'メールアドレスまたはパスワードが正しくありません。';
      case 'InvalidPasswordException':
        return 'パスワードは8文字以上の半角英数字で入力してください。';
      case 'InvalidParameterException':
        return '8文字以上の有効なパスワードを入力してください。';
      case 'LimitExceededException':
        return '試行回数が所定の回数を超えました。時間を置いて再度送信してください。';
      case 'UserAlreadyAuthenticatedException':
        return 'すでにログイン済みです。他のアカウントでログインする場合はマイページから一度ログアウトしてください。';
      case 'UsernameExistsException':
        return 'このメールアドレスで既に会員登録されています。認証済みでない場合はメールを確認してください。';
      case 'CodeMismatchException':
        return '無効な認証コードが入力されました。再度確認してください。';
      case 'ExpiredCodeException':
        return '無効な認証コードが入力されました。有効期限を確認してください。';
      case 'CodeDeliveryFailureException':
        return '認証コードの送信に失敗しました。再度認証コードを送信してください。';
      case 'InvalidRefreshToken':
        return 'リフレッシュトークンが無効です。';
      case 'NetworkError':
        return 'インターネット接続に失敗しました。';
      default:
        // その他のエラー
        return (
          procName +
          '処理でエラーが発生しました。(' +
          error.name +
          ':' +
          error.message +
          ')'
        );
    }
  };

  return {
    errorMessage,
    handleSignUp,
    handleSignUpConfirmation,
    handleResendSignUpCode,
    handleSignIn,
    handleLineSignIn,
    handleAppleSignIn,
    handleGoogleSignIn,
    handleLineIntegration,
    handleAppleIntegration,
    handleGoogleIntegration,
    isSignedIn,
    getCognitoTokens,
    getAccessToken,
    getCurrentProviderId,
    checkPassword,
    handleUpdateAttribute,
    handleUpdateAttributeConfirmation,
    handleResetPassword,
    handleResetPasswordConfirmation,
    handleUpdatePassword,
    handleSignOut,
    handleUnsubscribe,
    forceRefreshSession,
  };
};
